<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo" v-if="!isGefin">Cadastro de Agência</h1>

      <div class="box-table">
        <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-data-table
            :headers="headers"
            :search="search"
            :items="listaItens"
            width="300px"
            flat
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage"> 

            <template v-slot:item.phone="{ item }">
              {{ item.phone | phone2 }}
            </template>

            <template v-slot:item.parent.cod="{ item }">
              {{ item.parent.jsonData.name }} ({{ item.parent.cod }}) 
            </template>

            <template v-slot:item.type="{ item }">
              {{ typeEstabelecimento(item.type) }}
            </template>

            <template v-slot:top>
              <BrToolBar 
                @search="buscar" 
                :configFilter="{ 
                  listaSelect: item, 
                  getItens: getItens,
                  jsonData: true,
                }"
                :labelTextField="'Busca por Código'"
              >
                  <v-select
                    :items="itemsSelect"
                    label="Promotor"
                    hide-details
                    v-model="selectCredenciadorId" 
                  />
              </BrToolBar>
            </template>

            <template v-slot:item.action="{ item }">
              <!-- <v-btn
                small
                @click="activaModalListCaixa(item)"
                tile
                outlined 
                :color="variables.colorSecondary"
                class="mr-2"
              >
                Caixas
              </v-btn> -->

              <v-btn
                v-if="permiteAcao($route, 'edit')"
                small
                @click="editItem(item)"
                tile
                outlined 
                :color="variables.colorPrimary"
                class="mr-2"
              >
                Editar
              </v-btn>

              <v-btn
                v-else-if="permiteAcao({ path: '/cadastros/produtos/product-entities/edit-limit' } , 'edit')"
                small
                @click="openModalEditarLimite(item)"
                tile
                outlined 
                :color="variables.colorPrimary"
                class="mr-2"
              >
                Alterar Limit.
              </v-btn>

              <v-btn
                v-if="permiteAcao($route, 'delete')"
                small
                @click="deleteItem(item)"
                tile
                outlined 
                :color="variables.colorError"
              >
                Excluir
              </v-btn>
            </template>

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="7"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"></v-pagination>
              </div>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>

      <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-container>

    <modalListCaixa />
    <ModalEditarLimite 
      :isActiveModal="isActiveModalEditarLimite"
      :entity="entityEditarLimite"
      @close-modal="closeModalEditarLimite"
    />

    <ModalAutenticacao 
      :isActiveModal="activeModalAutenticacao" 
      @closeModal="closeModalAutenticacao"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Events from '@/core/service/events'
import { errorSnackbar } from '@/core/service/utils'

/**
 * MIXIN
 */
import listagemTables from '@/views/mixins/listagemTables'

export default {
  name: 'Cadastro',
  components: {
    BrToolBar: () => import('./components/toolbar'),
    modalListCaixa: () => import('./modalListCaixa'),
    ModalEditarLimite: () => import('./components/modalEditarLimite'),
    ModalAutenticacao: () => import('./components/modalAutenticacao')
  },
  mixins: [listagemTables],
  props: {
    isGefin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      typeBusca: 'entities',
      selectCredenciadorId: null,
      validateSelectCredenciadorId: null,
      isActiveModalEditarLimite: false,
      entityEditarLimite: {},
      loadingTable: false,
      activeModalAutenticacao: false
    }
  },

  watch: {
    selectCredenciadorId (val) {

      this.loadingPag = true
      if (val !== this.validateSelectCredenciadorId) {
        // this.getItens({ "Entities:parentId_ilike":`%${val}%` })
        this.getItens({ _filter: {'Entities:parentId_=': val} })
        .finally (() => this.loadingPag = false)
      }

      if (!val) this.getItens().finally(() => this.loadingPag = false)
    }
  },

  async mounted () {
    this.loadingTable = true
    // await this.getUser()

    // if (this.user) {
    //   const codigoPromotor = this.user.userEntityPaths.find(it => it.split('.').length === 3)
    //   if (codigoPromotor) {
    //     // const idSupervisor = codigoPromotor.split('.')[1]
    //     // const idPromotor = codigoPromotor.split('.')[2]
    //     this.activeModalAutenticacao = true
    //     this.loadingTable = false
    //     return 
    //   } 

    //   this.getData()
    // }

    this.getData()
    this.obterItensSelect({ pageSize: 50 })
    this.getBankList()
  },

  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('estabelecimento', ['listaItens', 'totalItens', 'item', 'listaItensSelectCredenciador']),
    ...mapGetters('entities', ['listEntityTypes']),

    itemsSelect () {
      return [ { text: 'Todos', value: null }, ...this.listaItensSelectCredenciador ]
    },

    headers () {
      if (this.isGefin) {
        return [
            { align: 'start', class: 'table-header', text: 'Código', value: 'cod' },
            { align: 'start', class: 'table-header', text: 'Promotor', value: 'parent.jsonData.name' },
            { align: 'start', class: 'table-header', text: 'Nome', value: 'name' },
            // { align: 'start', class: 'table-header', text: 'Responsavel', value: 'responsible.name' },
            { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
        ]
      }

      return this.permiteAcao(this.$route, 'add') ?  [
        { align: 'start', class: 'table-header', text: 'Código REV', value: 'cod' },
        // { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
        // { align: 'start', class: 'table-header', text: 'Tipo de estabelecimento', value: 'type' },
        { align: 'start', class: 'table-header', text: `Promotor`, value: 'parent.cod' },
        { align: 'start', class: 'table-header', text: 'Nome', value: 'name' },
        { align: 'start', class: 'table-header', text: 'Telefone', value: 'phone' },
        // { align: 'start', class: 'table-header', text: 'Responsavel', value: 'responsible.name' },
        { align: 'start', class: 'table-header', text: 'Data Criação', value: 'date' },
        { align: 'start', class: 'table-header', text: 'Data Atualização', value: 'updated' },
        { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
      ] :  [
        { align: 'start', class: 'table-header', text: 'Código', value: 'cod' },
        // { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
        // { align: 'start', class: 'table-header', text: 'Tipo de estabelecimento', value: 'type' },
        { align: 'start', class: 'table-header', text: `Promotor`, value: 'parent.cod' },
        // { align: 'start', class: 'table-header', text: 'Nome', value: 'name' },
        // { align: 'start', class: 'table-header', text: 'Telefone', value: 'phone' },
        // { align: 'start', class: 'table-header', text: 'Responsavel', value: 'responsible.name' },
        { align: 'start', class: 'table-header', text: 'Data Criacão', value: 'date' },
        { align: 'start', class: 'table-header', text: 'Data Atualização', value: 'updated' },
        { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
      ]
    } 
  },

  methods: {
    ...mapActions('estabelecimento', ['getItens', 'buscarItem', 'deletarItem', 'getItem', 'clearItens', 'obterItensSelect', 'obterItensSelectProducts', 'obterEntityProduct', 'getBankList']),
    ...mapActions('auth', ['getUser']),

    typeEstabelecimento (type) {
      switch(type){
        case (1):
          return '1 - Grande'
        case (2):
          return '2 - Médio'
        case (3):
          return '3 - Pequeno'
      }
    },

    init () {},

    getData () {
      this.loadingTable = true
      this.getItens({ page: this.page, pageSize: this.$store.getters.ItensPaginacao,  _addlFilter: this.filter ? this.filter : undefined })
        .then(result => {
          if (result.data.totalItens < 1) {
            errorSnackbar('Não há dados disponíveis')
          }
        })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => this.loadingTable = false)
    },

    activaModalListCaixa (item) {
     Events.$emit('estabelecimento::listaCaixa', item)
    },

    closeModalEditarLimite () {
      this.isActiveModalEditarLimite = false
    },

    openModalEditarLimite (entity) {
      this.entityEditarLimite = entity
      this.isActiveModalEditarLimite = true
    },
    cadastrar () {
      Events.$emit('cadastro::openModalCadastro2')
    },

    closeModalAutenticacao () {
      this.getData()
      this.activeModalAutenticacao = false
    }
  }
}
</script>

<style lang="scss">
  @import '../../../../assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
</style>>
