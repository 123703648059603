var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('h1',{staticClass:"titulo"},[_vm._v("Cadastros")]),_c('v-skeleton-loader',{attrs:{"type":"table","loading":_vm.skLoader,"transition-group":'fade-transition'}},[_c('v-row',{staticStyle:{"justify-content":"center"}},[_c('v-card',{attrs:{"elevation":"5"}},[_c('v-card-text',[_c('v-container',[_c('v-row',{staticStyle:{"margin":"0px"}},[_c('v-menu',{attrs:{"transition":"slide-y-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"newContact",attrs:{"depressed":""}},'v-btn',attrs,false),on),[_vm._v(" Novo Cadastro "),_c('v-list-item-avatar',{staticStyle:{"margin":"8px"},attrs:{"size":"24"}},[_c('img',_vm._g(_vm._b({attrs:{"src":'https://cdn4.iconfinder.com/data/icons/simplicio/128x128/notification_add.png'}},'img',attrs,false),on))])],1)]}}])},[_c('v-list',_vm._l((_vm.contactTypes),function(item,i){return _c('v-list-item',{key:i,attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.openModalCadastro({ contactType: item })}}},[_vm._v(_vm._s(item.name))])],1)}),1)],1)],1),_c('v-tabs',{staticStyle:{"margin-top":"16px","border-radius":"16px 16px 0px 0px !important"},attrs:{"background-color":_vm.variables.colorPrimary,"dark":"","center-active":"","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"#000000"}}),_c('v-tab',{attrs:{"href":"#tab-0"}},[_vm._v("Geral")]),_c('v-tab',{attrs:{"href":"#tab-1"}},[_vm._v("Clientes")]),_c('v-tab',{attrs:{"href":"#tab-2"}},[_vm._v("Fornecedores")]),_c('v-tab',{attrs:{"href":"#tab-3"}},[_vm._v("Redes")]),_c('v-tab',{attrs:{"href":"#tab-4"}},[_vm._v("Outros")])],1),_c('v-form',[_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:0,attrs:{"value":'tab-0'}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.allContacts},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('OrganizzeActionButton',{attrs:{"canEdit":_vm.permiteAcao(_vm.$route, 'edit'),"canDelete":_vm.permiteAcao(_vm.$route, 'delete'),"canRecibo":false},on:{"onEdit":function($event){return _vm.openModalCadastro({ contact: item })},"onDelete":function($event){return _vm.openModalExcluir({ contact: item })}}})]}}],null,true)})],1),_c('v-tab-item',{key:1,attrs:{"value":'tab-1'}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.customers},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('OrganizzeActionButton',{attrs:{"canEdit":_vm.permiteAcao(_vm.$route, 'edit'),"canDelete":_vm.permiteAcao(_vm.$route, 'delete'),"canRecibo":false},on:{"onEdit":function($event){return _vm.openModalCadastro({ contact: item })},"onDelete":function($event){return _vm.openModalExcluir({ contact: item })}}})]}}],null,true)})],1),_c('v-tab-item',{key:2,attrs:{"value":'tab-2'}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.providers},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('OrganizzeActionButton',{attrs:{"canEdit":_vm.permiteAcao(_vm.$route, 'edit'),"canDelete":_vm.permiteAcao(_vm.$route, 'delete'),"canRecibo":false},on:{"onEdit":function($event){return _vm.openModalCadastro({ contact: item })},"onDelete":function($event){return _vm.openModalExcluir({ contact: item })}}})]}}],null,true)})],1),_c('v-tab-item',{key:3,attrs:{"value":'tab-3'}},[(_vm.loadingRevenda)?_c('v-col',{staticClass:"d-flex justify-center align-center",staticStyle:{"min-height":"150px","width":"100%"}},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1):_c('estabelecimento',{attrs:{"isGefin":true}})],1),_c('v-tab-item',{key:4,attrs:{"value":'tab-4'}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.others},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('OrganizzeActionButton',{attrs:{"canEdit":_vm.permiteAcao(_vm.$route, 'edit'),"canDelete":_vm.permiteAcao(_vm.$route, 'delete'),"canRecibo":false},on:{"onEdit":function($event){return _vm.openModalCadastro({ contact: item })},"onDelete":function($event){return _vm.openModalExcluir({ contact: item })}}})]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)],1)],1),_c('ModalCadastro'),_c('ModalExcluir')],1)}
var staticRenderFns = []

export { render, staticRenderFns }