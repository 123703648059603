<template>
  <div>
    <v-container fluid>
      <h1 class="titulo">Cadastros</h1>
      <v-skeleton-loader type="table" :loading="skLoader" :transition-group="'fade-transition'">
        <v-row style="justify-content: center;">
          <v-card elevation="5">
            <v-card-text>
              <v-container>
                <v-row style="margin: 0px;">
                  <v-menu transition="slide-y-transition" offset-y>
                    <template
                      v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="newContact"
                        v-bind="attrs"
                        v-on="on"
                        depressed>
                        Novo Cadastro
                        <v-list-item-avatar size="24" style="margin: 8px;">
                          <img
                            v-bind="attrs"
                            v-on="on"
                            :src="'https://cdn4.iconfinder.com/data/icons/simplicio/128x128/notification_add.png'"/>
                        </v-list-item-avatar>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        v-for="(item, i) in contactTypes"
                        :key="i"
                        link>
                        <v-list-item-title @click="openModalCadastro({ contactType: item })">{{ item.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-row>

                <v-tabs
                  v-model="tab"
                  style="margin-top: 16px; border-radius: 16px 16px 0px 0px !important;"
                  :background-color="variables.colorPrimary"
                  dark
                  center-active
                  show-arrows>
                  <v-tabs-slider color="#000000"></v-tabs-slider>
                  <v-tab href="#tab-0">Geral</v-tab>
                  <v-tab href="#tab-1">Clientes</v-tab>
                  <v-tab href="#tab-2">Fornecedores</v-tab>
                  <v-tab href="#tab-3">Redes</v-tab>
                  <v-tab href="#tab-4">Outros</v-tab>
                </v-tabs>

                <v-form>
                  <v-tabs-items v-model="tab" touchless>
                    <v-tab-item :key="0" :value="'tab-0'">
                      <v-data-table
                        :headers="headers"
                        :items="allContacts">
                        <template v-slot:[`item.action`]="{ item }">
                          <OrganizzeActionButton
                            :canEdit="permiteAcao($route, 'edit')"
                            :canDelete="permiteAcao($route, 'delete')"
                            :canRecibo="false"
                            @onEdit="openModalCadastro({ contact: item })"
                            @onDelete="openModalExcluir({ contact: item })"/>
                        </template>
                      </v-data-table>
                    </v-tab-item>

                    <v-tab-item :key="1" :value="'tab-1'">
                      <v-data-table
                        :headers="headers"
                        :items="customers">
                        <template v-slot:[`item.action`]="{ item }">
                          <OrganizzeActionButton
                            :canEdit="permiteAcao($route, 'edit')"
                            :canDelete="permiteAcao($route, 'delete')"
                            :canRecibo="false"
                            @onEdit="openModalCadastro({ contact: item })"
                            @onDelete="openModalExcluir({ contact: item })"/>
                        </template>
                      </v-data-table>
                    </v-tab-item>

                    <v-tab-item :key="2" :value="'tab-2'">
                      <v-data-table
                        :headers="headers"
                        :items="providers">
                        <template v-slot:[`item.action`]="{ item }">
                          <OrganizzeActionButton
                            :canEdit="permiteAcao($route, 'edit')"
                            :canDelete="permiteAcao($route, 'delete')"
                            :canRecibo="false"
                            @onEdit="openModalCadastro({ contact: item })"
                            @onDelete="openModalExcluir({ contact: item })"/>
                        </template>
                      </v-data-table>
                    </v-tab-item>

                    <v-tab-item :key="3" :value="'tab-3'">
                      <v-col 
                        v-if="loadingRevenda"
                        class="d-flex justify-center align-center" style="min-height: 150px; width: 100%;">
                        <v-progress-circular
                          :size="50"
                          color="primary"
                          indeterminate
                        ></v-progress-circular>
                      </v-col>
                      
                      <estabelecimento 
                        v-else
                        :isGefin="true"
                      />
                      
                    </v-tab-item>

                    <v-tab-item :key="4" :value="'tab-4'">
                      <v-data-table
                        :headers="headers"
                        :items="others">
                        <template v-slot:[`item.action`]="{ item }">
                          <OrganizzeActionButton
                            :canEdit="permiteAcao($route, 'edit')"
                            :canDelete="permiteAcao($route, 'delete')"
                            :canRecibo="false"
                            @onEdit="openModalCadastro({ contact: item })"
                            @onDelete="openModalExcluir({ contact: item })"/>
                        </template>
                      </v-data-table>
                    </v-tab-item>
                  </v-tabs-items>
                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-row>
      </v-skeleton-loader>
    </v-container>
    <ModalCadastro/>
    <ModalExcluir/>
  </div>
</template>

<script>
import Events from '@/core/service/events'
import { errorSnackbar } from '@/core/service/utils'
import { mapActions, mapGetters } from 'vuex'
import variables from '@/assets/styles/helpers/_variables.scss'
import { incRequestCounter, REQUEST_KEY_ALL } from '@/utils/miscellaneous'
import listagemTables from '@/views/mixins/listagemTables'
import estabelecimento from '@/views/cadastros/entities/estabelecimento'

const namespaceStore = 'organizzeContatos'

export default {
  name: 'OrganizzeContatos',

  mixins: [listagemTables],

  components: {
    ModalCadastro: () => import('./components/modalCadastro'),
    ModalExcluir: () => import('./components/modalExcluir'),
    OrganizzeActionButton: () => import('@/views/components/organizze/actionButton/index.vue'),
    estabelecimento
  },

  computed: {
    ...mapGetters(namespaceStore, ['contactTypes', 'allContacts', 'customers', 'providers', 'others']),
    ...mapGetters('roles', ['permiteAcao']),
    variables: () => variables
  },

  data: () => ({
    skLoader: false,
    tab: 'tab-0',
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Name', value: 'name' },
      { text: 'Email', value: 'email' },
      { text: 'Celular', value: 'phone' },
      { text: '', value: 'action', align: 'end' }
    ],
    headersRevendas: [
      { text: 'ID', value: 'id' },
      { text: 'Name', value: 'name' },
      { text: 'Email', value: 'email' },
      { text: 'Celular', value: 'phone' },
      { text: '', value: 'action', align: 'end' }
    ],
    loadingRevenda: false
  }),

  watch: {
    tab (val) {
      if (val === 'tab-3') {
        this.loadingRevenda = true
        this.getItens()
          .finally(() => this.loadingRevenda = false)
      }
    }
  },

  created () {},

  mounted () {
    const self = this
    self.buscarTiposContato()
    self.buscarContatos()

    Events.$on('index::getContacts', () => { self.buscarContatos() })
  },

  methods: {
    ...mapActions(namespaceStore, ['getContactTypes', 'getContacts']),
    ...mapActions('estabelecimento', ['getItens']),

    openModalCadastro (val) { Events.$emit('modalCadastro::open', val) },
    openModalExcluir (val) { Events.$emit('modalExcluir::open', val) },

    init () {},

    buscarTiposContato () {
      const self = this
      self.skLoader = incRequestCounter(REQUEST_KEY_ALL, 1)
      self.getContactTypes().then(() => {}).catch(e => {
        console.error(e)
        errorSnackbar(e)
      }).finally(() => {
        self.skLoader = incRequestCounter(REQUEST_KEY_ALL, -1)
      })
    },

    buscarContatos () {
      const self = this
      self.skLoader = incRequestCounter(REQUEST_KEY_ALL, 1)
      self.getContacts().then(() => {}).catch(e => {
        console.error(e)
        errorSnackbar(e)
      }).finally(() => {
        self.skLoader = incRequestCounter(REQUEST_KEY_ALL, -1)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';

  .newContact {
    color: $colorPrimary;
    align-self: center;
    font-size: 16px;
    text-transform: uppercase;
  }

  .v-form {
    padding: 0px 16px 0px;
    border: 8px solid $colorPrimary;
    border-radius: 0px 0px 16px 16px !important;
  }
</style>