import { isOnlyNumber } from './validationUtils'

let requestCounter = {}

export const REQUEST_KEY_ALL = 'all'
export const REQUEST_KEY_ORGANIZZE_FILTER = 'organizeFilter'

export const makeSkeletonData = (self, val, result) => {
  if (Array.isArray(val)) {
    val.forEach(() => result.push({}))
  } else {
    if (typeof val === 'object') {
      for (const key in val) {
        if (Object.hasOwnProperty.call(val, key)) {
          if (Array.isArray(val[key])) self.$set(result, key, [])
          else if (typeof val[key] === 'object') self.$set(result, key, {})
          else self.$set(result, key, val[key])
          makeSkeletonData(self, val[key], result[key])
        }
      }
    }
  }
}

export const incRequestCounter = (key, val) => {
  if (isOnlyNumber(val)) {
    if (requestCounter[key] === undefined) requestCounter[key] = val
    else requestCounter[key] += val
  }

  return requestCounter[key] !== 0
}